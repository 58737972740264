import { Link } from "react-router-dom"


export const MenuButtonBottomMenu = ({title, icon, selected, func, isLink, to}) => {
    
    return (
        isLink==true?
       
            <Link to={to} onClick={()=>{
                const SideMenuDOM = document.querySelector('.side-menu-container');
               
                if(SideMenuDOM.classList.contains('side-menu-container-open')){
                     SideMenuDOM.style.display='none'
                    SideMenuDOM.classList.remove('side-menu-container-open')
                }
            }} className={`menu-button-bottom-menu ${selected?'menu-button-bottom-menu-selected':''}`}>
                <div className="menu-button-bottom-menu-icon">
                    {icon}
                </div>
                <div className="menu-button-bottom-menu-title">
                    {title}
                </div>
        </Link>:
        <div className={`menu-button-bottom-menu ${selected?'menu-button-bottom-menu-selected':''}`} onClick={()=>{
            func();
            if(title!="Menu"){
                const SideMenuDOM = document.querySelector('.side-menu-container');
               
                if(SideMenuDOM.classList.contains('side-menu-container-open')){
                     SideMenuDOM.style.display='none'
                    SideMenuDOM.classList.remove('side-menu-container-open')
                }
            }
        }}>
            <div className="menu-button-bottom-menu-icon">
                {icon}
            </div>
            <div className="menu-button-bottom-menu-title">
                {title}
            </div>
        </div>
    )
}