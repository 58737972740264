
import React, { createContext, useEffect, useRef, useState } from 'react'
import { Loader } from '../Loader';
import 'react-toastify/dist/ReactToastify.css';
import Header from '../main/Header';
import EarningFeed from '../main/EarningFeed';
import ChatContainer from '../chat/ChatContainer';
import { io } from 'socket.io-client';
import LeftMenu from '../main/LeftMenu';
import '@material-tailwind/react'
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { ChangeStateUrl, CreateToast, hideChangePWModal, removeLocalStorage, setLocalStorage, showChangePWModal, submitFingers, EUC, getLocalStorage, isLoggedIn, showPhoneNumberVerifications, hasSomeParentTheClass, getChatStatus, getIsBanned, NotAvailableCountry, getFingerprints, getUserID, getUsername, submitFingersDRO, getUserIDFromSesion, hideLoginModal, hideRegisterModal, hideResetPWModal, showLoginModal, hideAdJoeModal, hideAdjoePWModal } from '../utils';
import { GoogleOAuthProvider } from '@react-oauth/google';
import LoginModal from '../modals/LoginModal';
import SignupModal from '../modals/SignupModal';
import ForgotPasswordModal from '../modals/ForgotPasswordModal';
import { useCookies } from 'react-cookie'
import { ToastContainer } from 'react-toastify';
import "primereact/resources/primereact.min.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import PhoneNumberModal from '../modals/PhoneNumberModal';
import SendSMSPhoneNumberModal from '../modals/SendSMSPhoneNumberModal';
import ReferralModal from '../modals/ReferralModal';
import RewardsModal from '../modals/RewardsModal';
import WithdrawMessageModal from '../modals/WithdrawMessageModal';
import queryString from 'query-string';
import ChangePasswordModal from '../modals/ChangePasswordModal';
import FooterMainCont from '../main/FooterMain';
import BottomMenu from '../main/BottomMenu';
import FaucetModal from '../modals/FaucetModal';
import 'vanilla-cookieconsent';
import 'vanilla-cookieconsent/dist/cookieconsent.css';
import ReactGA from 'react-ga4';
import { clarity } from 'react-microsoft-clarity';
import GiftCardModal from '../modals/GiftCardModal';
import BannedModal from '../modals/BannedModal';
import fingerprintjs from '@fingerprintjs/fingerprintjs';
import UsersModal from '../modals/UsersModal';
import OffersModal from '../modals/OffersModal';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import HomePage from '../Pages/HomePage';
import EarnPage from '../Pages/EarnPage';
import WithdrawPageContainer, { CashOutContainer } from '../../Containers/CashOut.Container';
import WithdrawOptionPage from './WithdrawItemContainer';
import LeaderboardPage from './LeaderboardPageContainer';
import OffersPage from './OffersPageContainer';
import OffersCategoryPage from './OffersCatPageContainer';
import UserDetailsPageContainer from './UserDetailsPageContainer';
import ProfileContainer from './ProfileContainer';
import TermsOfServicePageCCPA from './CCPAContainer';
import TermsOfServicePageTerms from './TermsContainer';
import TermsOfServicePagePrivacy from './PrivacyContainer';
import TermsOfServicePagePrivacyEU from './PrivacyEuContainer';
import TermsOfServicePageCookies from './CookiesContainer';
import OfferDetailsPageContainer from './OfferDetailsPageContainer';
import FAQ from '../Pages/FaqPage';
import AADSContainer from './AADSContainer';
import ContactPage from './ContactPageContainer';
import DocumentMeta from 'react-document-meta';
import $ from 'jquery';
import { AdminHome } from '../plutoComp/AdminHome';
import NotAvailableInYourCountry from './NotAvailableInYourCountry';
import { ConsoleNetworkOutline, Cookie } from 'mdi-material-ui';
import axios from 'axios';
import Zendesk from "react-zendesk";
import AdJoeModal from '../modals/AdJoeModal';
import { HeaderContainer } from '../../Containers/Header.Container';
import { SideMenuContainer } from '../../Containers/SideMenu.Container';
import { EarningFeedContainer } from '../../Containers/EarningFeed.Container';
import { AnnouncementsContainer } from '../../Containers/Announcements.Container';
import { PromotedSmallOfferContainer } from '../../Containers/PromotedSmallOffer.Container';
import { WallsContainer } from '../../Containers/Walls.Container';
import { PromotedSurveysContainer } from '../../Containers/PromotedSurveys.Container';
import { BottomMenuContainer } from '../../Containers/BottomMenu.Container';
import { UserModalContent } from '../../Containers/ModalContent/User.ModalContent';
import { InfoModalContent } from '../../Containers/ModalContent/Info.ModalContent';
import { WallModalContent } from '../../Containers/ModalContent/Wall.ModalContent';
import { CashOutOptionContainer } from '../../Containers/CashoutOption.Container';
import { EarnHomeContainer } from '../../Containers/EarnHome.Container';
import { OffersContainer } from '../../Containers/Offers.Container';
import PTCAdsView from '../../Containers/PTCAds/WatchPTC';
import PTCFramelessAdsShow from '../../Containers/PTCAds/PTCFramelessAdsShow';
import PTCAdsShow from '../../Containers/PTCAds/PTCAdsShow';
import Advertise from '../../Containers/Advertise/Advertise';
import AdDashboard from '../../Containers/Advertise/AdDashboard';
import AdDashDeposits from '../../Containers/Advertise/AdDashDeposits';
import { PromoCodeContainer } from '../../Containers/PromoCode.Container';
import { RewardedVideoAds } from '../../Containers/RewardedVideoAds.Container';
import { FooterContainer } from '../../Containers/Footer.Container';
import { DailyRewardsContainer } from '../../Containers/DailyRewards.Container';
import { TermsOfServices } from '../../Containers/TermsOfServices.Container';
import { PrivacyEU } from '../../Containers/PrivacyEU.Container';
import { Cookies } from '../../Containers/Cookies.Container';
import { CCPA } from '../../Containers/CCPA.Container';
import { Privacy } from '../../Containers/Privacy.Container';
import { ReferralData } from '../../Containers/ReferralData.Container';
import { FaucetModalContent } from '../../Containers/ModalContent/Faucet.ModalContent';
import { LeaderboardContainer } from '../../Containers/Leaderboard.Container';
import { UserProfileContainer } from '../../Containers/Profile.Container';
import { UserProfileSettingsContainer } from '../../Containers/ProfileSettings.Container';
import { HomeScreenContainer } from '../../Containers/HomeScreen.Container';
import { LoginModalContent } from '../../Containers/ModalContent/Login.ModalContent';
import { SignupModalContent } from '../../Containers/ModalContent/Signup.ModalContent';
import { ForgotPasswordModalContent } from '../../Containers/ModalContent/ForgotPassword.ModalContent';
import { ResetPasswordModalContent } from '../../Containers/ModalContent/ResetPassword.ModalContent';
import { DownloadMobileModalContent } from '../../Containers/ModalContent/DownloadMobile.ModalContent';
import { BannedModalContent } from '../../Containers/ModalContent/BannedModal.ModalContent';
import { Christmas2024Container } from '../../Containers/Christmas2024.Container';

export let socket = undefined;

export const MainContainerContext = createContext("");

function MainContainer({ children, modal }) {
    const setting = {
        color: {
          theme: "#000"
        },
        launcher: {
          chatLabel: {
            "en-US": "Need Help"
          }
        },
        contactForm: {
          fields: [
            { id: "description", prefill: { "*": "My pre-filled description" } }
          ]
        }
      };
    const [loaded24Metrics, setLoaded24Metrics] = useState(false)
    let [loggedIN, setLogged] = useState(isLoggedIn());
    useEffect(() => { setLogged(isLoggedIn()) }, [isLoggedIn()])
    const [notAvailable, setNotAvailable] = useState(false)
    const { referral_id, page, second_page, third_page } = useParams();
    const [cookies, setCookie, removeCookie] = useCookies(['faucetify.io'])
    const [balance, setBalance] = useState(0);
    const [rank, setRank] = useState(0);
    const [level, setLevel] = useState(0);
    const [banned, setBanned] = useState(false);
    const [username, setUsername] = useState('')
    const [userID, setID] = useState('')
    const [isMessage, setIsMessage] = useState(false);
    const [messageBanner, setMessageBanner] = useState('')
    const [verifySession, setVerifySession] = useState(false);
    const [isPhoneV, setISPhoneV] = useState(false);
    const [profilePicture, setProfilePic] = useState('')
    const [called24M, setCalled24M] = useState(false)
    useEffect(() => {
        $('document').ready(function () {
            if (document.querySelector('#body-top-scr') != null) {
                document.querySelector('#body-top-scr').scrollTo({ top: 0, behavior: 'smooth' })
            }
        })
    }, [page, second_page, third_page])

    const [mainLoading, setMainLoading] = useState(true)
    const creaRef = useRef(null);
    useEffect(async () => {
        if(page=='r'){
            let params = queryString.parse(window.location.search)
            if(params.mob=='1' || params.mob==1){
                window.location.href = 'https://play.google.com/store/apps/details?id=com.ml.paidcash&referrer='+second_page
            }
        }
        if(page=='reg_cookie'){
            HandleAfterApp()
        }
        if (socket == undefined) {
            checkFunctionsEnabled()
        }

        socket.on('itcm', (data) => {
            
            (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/og1c5x7m';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
            window.Intercom("boot", {
                api_base: "https://api-iam.intercom.io",
                app_id: "og1c5x7m",
                user_id: data.user_id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
                name: data.username, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
                email: data.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email address
                created_at: data.joined_at, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date
              });
        })
        
        socket.on('userCountry', (data) => {
            if (NotAvailableCountry(data.country) == false) {

                // ReactGA.initialize('G-GJB978CGFN')
                // clarity.init('ehbdsgyr0l');
            } else {
                setNotAvailable(true)
            }
        })

        socket.on('hideModalSignup', (data) => {
            hideChangePWModal()
        })
        socket.on('updateBalance', (data) => {
            setBalance(data.amount)
        })
        socket.on('showBanned', (data) => {

            setLocalStorage("banned", data.banned)
            window.dispatchEvent(new Event('setBanned'))
            try{
            document.querySelector('.modal-content-banned').parentNode.style.display = 'flex'
            }catch(e){}

        })
        socket.on('checkMessage', (data) => {
            setID(data.id);
            setUsername(data.username)
            setIsMessage(data.isMessage)
            setBanned(data.banned)
            setLocalStorage("banned", data.banned)
            if (data.banned == true) {
                window.dispatchEvent(new Event('setBanned'))
                document.querySelector('.modal-content-banned').parentNode.style.display = 'flex'
            }
            setMessageBanner(data.message)
            setRank(data.rank)
            setLevel(data.level)
            setVerifySession(data.verify)
            setISPhoneV(data.phone_verify)
            if (data.phone_verify == true) {
                window.dispatchEvent(new Event('showPhoneVerifyBanner'))
            }
        })
        // socket.on('fetchChecks', (data) => {

        //     if (cookies['last_tick'] == undefined) {
        //         if (loggedIN == true) {
        //             socket.emit('fetchChecksUrl', { url: window.location.href })
        //             navigate(`/checks`)
        //         }
        //     }
        // })
        socket.emit('getOnlineUsers')

        socket.on('userActionResponse', (data) => {
            CreateToast(data.message, creaRef, data.type)
        })
        socket.on('profilePicture', (data) => {
            setProfilePic(data.img)
        })
        socket.on('deniedAction', (data) => {
            CreateToast(data.message, creaRef, 1)
        })
        socket.on('proxy_on_wall', () => { window.location.href = window.location.href })

        socket.on('logOut', (data) => {
            setLogged(false)
            socket.emit('logOut')
            removeCookie('last_tick')
            if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                window.PaidCashChannel.postMessage('logout');
            }
            removeCookie('session_token')
            window.location.href = '/'
        })
        socket.on('logIn', async (data) => {
            setCookie('session_token', data.session, { path: '/', expires: new Date(data.expireAt) })
            
            if (typeof window.PaidCashChannel !== 'undefined' && window.PaidCashChannel.postMessage) {
                window.PaidCashChannel.postMessage('login');
            }
            setCookie('user_id', getUserID(), { path: '/', expires: new Date(data.expireAt) })
            let fp = await getFingerprints(fingerprintjs)
            let visitor_id = '';
            if (fp != undefined) {
                visitor_id = fp.visitorId
            }
            //axios.get(`https://connecting.paidcash.co/?sub_id=${data.session}&publisher=${getUserID()}&fingerprint=${visitor_id}`)
            // axios.post(`https://util.paidcash.co/checks?session_id=${data.session}`, { followRedirects: true })
            socket.close()
            window.dispatchEvent(new Event('loggedIn'));
        })
        if (cookies['session_token'] != undefined) {
            setTimeout(() => {
                socket.emit('checkBanned')
            }, 1000)
            // document.onreadystatechange = () => {
            // let iframe = document.createElement('iframe');
            // iframe.src = '';
            // iframe.style.display = 'none';
            // iframe.onload = function () {
            //     try {
            //         let dataS = document.createElement('script');
            //         dataS.innerHTML = ` let cs_params = {
            //     "integration_id": "n58m4ow07s",
            //     sub_id: '${cookies['session_token']}',
            //     publisher: '${getUserID()}'
            // };`
            //         let script = document.createElement('script');
            //         script.src = 'https://static.24metrics.com/js/index.js';
            //         iframe.contentDocument.body.appendChild(dataS);
            //         iframe.contentDocument.body.appendChild(script);

            //     } catch (e) {
            //         setTimeout(() => {
            //             document.body.removeChild(iframe)
            //         }, 1000)
            //     }
            // };
            // document.body.appendChild(iframe);
            setCalled24M(true)
            window.ASP.trackClick({
                integrationID: 'n58m4ow07s',
                sub_id: cookies['session_token'],
                publisher: getUserID(),
                fields: "redirect_url"
                // Add more parameters if needed
            }).then(function (response) {
                // Do something with the response?
                axios.get(response.data.redirect_url)
            }).catch(x => {
                axios.get(`https://connecting.paidcash.co/?sub_id=${cookies['session_token']}&publisher=${getUserID()}&fingerprint=${visitor_id}`)

            });
            // }

            let fp = await getFingerprints(fingerprintjs)
            let visitor_id = '';
            if (fp != undefined) {
                visitor_id = fp.visitorId
            }
            setCookie('user_id', getUserID(), { path: '/' })
            //axios.get(`https://connecting.paidcash.co/?sub_id=${cookies['session_token']}&publisher=${getUserID()}&fingerprint=${visitor_id}`)
            // axios.post(`https://util.paidcash.co/checks?session_id=${cookies['session_token']}`, { followRedirects: true })
            setLogged(true)
            setLocalStorage("banned", false)
            if (typeof fingerprintjs == 'null') {
                setTimeout(() => {
                    submitFingers(fingerprintjs);
                }, 1000)
            } else {
                submitFingers(fingerprintjs);
            }
        } else {

        }
        window.addEventListener('click', (e) => {
            // if (e.target.classList.contains('username-chat')) {

            if (e.target.classList != undefined) {
                let arr = Object.values(e.target.classList)
                if (arr.map(x => x.includes('username-chat')).includes(true)) {
                    let parent = e.target.parentNode.parentNode.parentNode.parentNode
                    if (parent.classList.contains('chat-mid-msg')) {

                        window.dispatchEvent(new CustomEvent('showUserID', { detail: { "userID": parent.getAttribute('data-user-id') } }))
                    }

                }
                if (e.target.classList.contains('chat-user-tag')) {

                    window.dispatchEvent(new CustomEvent('showUserID', { detail: { "userID": e.target.getAttribute('data-user') } }))
                }
            }
            // }
            if (document.querySelector('.device-selector') != null) {
                if (document.querySelector('.device-selector').getAttribute('data-headlessui-state') == "open") {

                    if (!hasSomeParentTheClass(e.target, 'device-selector')) {

                        window.dispatchEvent(new Event('closeDeviceSelector'))
                    }
                }
            }
            if (loggedIN == true) {
                try {
                    if (document.querySelector('.profile-selector').getAttribute('data-headlessui-state') == "open") {

                        if (!hasSomeParentTheClass(e.target, 'profile-selector')) {

                            window.dispatchEvent(new Event('closeProfileSelector'))
                        }
                    }
                } catch (e) { }
            }
        })
        window.addEventListener('loggedIn', () => {
            window.location.href = window.location
        })
        window.addEventListener('loggedOut', () => {
            setLogged(false)
            socket.emit('logOut')
            removeCookie('session_token')
            window.location.href = '/'

        })


    }, []);
    async function HandleAfterApp () {
        let params = queryString.parse(window.location.search)
        let fp = await getFingerprints(fingerprintjs)
        let visitor_id = '';
        if (fp != undefined) {
            visitor_id = fp.visitorId
        }
        let user_id = getUserIDFromSesion(params.session)
        await socket.emit('submitFingersDRO', {session:params.session, fp: fp})
        await socket.emit('shouldCheckNow', {session:params.session, visitor_id: visitor_id})
       
    }
    useEffect(() => {
        socket.on('sureCheckNow', (data) => {
            window.ASP.trackClick({
                integrationID: 'n58m4ow07s',
                sub_id: data.session,
                publisher: data.user_id,
                fields: "redirect_url"
                // Add more parameters if needed
            }).then(function (response) {
                // Do something with the response?
                axios.get(response.data.redirect_url)
            }).catch(x => {
                axios.get(`https://connecting.paidcash.co/?sub_id=${data.session}&publisher=${data.user_id}&fingerprint=${data.visitor_id}`)
    
            });
        })
    }, [])
    const navigate = useNavigate();
    function checkFunctionsEnabled() {
        if (localStorage != undefined && window != undefined) {
            if (!document.getElementById('cc--main')) {
                window.CC = window.initCookieConsent();
                window.CC.run(pluginConfig);

                document.documentElement.classList.toggle('c_darkmode');
                if (cookies['cc_cookie'] == undefined) {

                    // window.CC.showSettings(0);
                }
            }
            let params = queryString.parse(window.location.search)
            window.newVerificationEmail = () => {
                socket.emit('userAction', {
                    action: 'new_ver_email'
                })
            }
            window.newVerificationSMS = () => {
                window.dispatchEvent(new Event('showPhoneVerifyModals'))
                setTimeout(() => {
                    showPhoneNumberVerifications()
                }, 500)
            }
            if (params.verifyKey != undefined) {
                if (params.verifyKey.split("").length > 20) {
                    setTimeout(() => {
                        socket.emit('userAction', {
                            action: 'verify_account',
                            code: params.verifyKey,
                        })
                        ChangeStateUrl("")
                    }, 0)
                }
            }
            if (params.deleteKey != undefined) {
                if (params.deleteKey.split("").length > 20) {
                    setTimeout(() => {
                        socket.emit('userAction', {
                            action: 'delete_account',
                            code: params.deleteKey,
                        })
                        ChangeStateUrl("")
                    }, 0)
                }
            }
            if (params.resetKey != undefined) {
                if (params.resetKey.split("").length > 20) {
                    setTimeout(() => {
                        showChangePWModal()
                        // ChangeStateUrl("")
                    }, 1000)
                }
            }
            if (params.unsubscribe != undefined) {
                if (params.unsubscribe.split("").length > 20) {
                    setTimeout(() => {
                        socket.emit('userAction', {
                            action: 'unsubscribe_mail',
                            token: params.unsubscribe,
                        })
                        ChangeStateUrl("")
                    }, 0)
                }
            }
            if (params.checking != undefined) {
                setTimeout(() => {
                    socket.emit('userAction', {
                        action: 'checks_done',
                        params,
                    })
                    socket.on('checks_done_url', (data) => {
                        navigate(data.url)
                    })
                }, 0)
            }
            removeLocalStorage('wallname')
            window.addEventListener('showPhoneVerifyModals', () => {
                setUpShowPhoneModals()
            })
            window.addEventListener('hidePhoneVerifyModals', () => {
                setUpHidePhoneModals()
            })
            window.addEventListener('showChat', () => {
            })
            socket = io(
                // 'http://localhost:3039', {
                'https://servers.faucetify.io/', {
                extraHeaders: {
                    Authorization: cookies['session_token'],
                },
            });
            socket.on('connect', () => {
                setMainLoading(false)

                if (cookies['session_token'] != undefined) {
                    setTimeout(() => {
                        socket.on('checkBanned')
                    }, 1000)
                    setLogged(true)
                    setLocalStorage("banned", false)

                    submitFingers(fingerprintjs);
                }
            });
        } else {
            setTimeout(() => {
                checkFunctionsEnabled()
            }, 1000)
        }

    }
    function setUpShowPhoneModals() {
        setISPhoneV(true)
        setTimeout(() => {
            showPhoneNumberVerifications()

        })
    }
    function setUpHidePhoneModals() {
        setISPhoneV(false)

    }
    const [chatStatus, setChatStatus] = useState(getChatStatus())
    useEffect(() => {
        window.addEventListener('showChat', () => {
            setChatStatus(getChatStatus())
            // .intercom-namespace .intercom-with-namespace-52k34s, .intercom-lightweight-app-launcher
   
        })
    }, [])
    const [selOfferID, setSelOfferID] = useState(-1)
    const [selUserID, setSelUserID] = useState(-1)
    const [infoModalData, setInfoModalData] = useState({show:false, title:'', description:'', image:'', type:'small'})
    const [showFaucetModal, setShowFaucetModal] = useState(false)
    useEffect(() => {
        window.addEventListener('showOfferID', (data) => {
            setSelOfferID(data.detail.offerID)
        })
        window.addEventListener('hideOfferID', () => {
            setSelOfferID(-1)
        })
    }, [])
    useEffect(() => {
        window.addEventListener('showInfoModal', (data) => {
            setInfoModalData({ show:true, title:data.detail.title, description:data.detail.description, image:data.detail.image, type:data.detail.type, canClaim:data.detail.canClaim})
        })
        window.addEventListener('hideInfoModal', () => {
            setInfoModalData({ show:false, title:'', description:'', image:'', type:'small'})
        })
        window.addEventListener('showFaucetModal', () => {
            setShowFaucetModal(true)
        })
        window.addEventListener('hideFaucetModal', () => {
            setShowFaucetModal(false)
        })
    }, [])
    useEffect(() => {
        window.addEventListener('showUserID', (data) => {
            if (loggedIN == false) {
                showLoginModal()
            } else {
                setSelUserID(data.detail.userID)
            }
        })
        window.addEventListener('hideUserID', () => {
            setSelUserID(-1)
        })
        
    }, [])

    const [wallsUp, setWallsUp] = useState({})
    function handleWallModal(title){
        let wall = document.querySelector(`.modal-content-wall-${title.split(' ').join('-')}`);
        if(wall == undefined){
            setWallsUp({...wallsUp, [title.split(' ').join('-')]:<WallModalContent click={(e)=>{e.currentTarget.parentNode.parentNode.parentNode.style.display ='none'}} title={title}/>})
            // handleWallModal(title)
        }else{
            wall.parentNode.style.display = 'block'
        }
    }
    return (
        notAvailable == true && page != 'pluto' ? <NotAvailableInYourCountry /> :
            // <ReCaptchaProvider reCaptchaKey="6Lc4TnUoAAAAAO8IRevUzsNiYBF9D7MgKfyEiOSg" useEnterprise>
            <SkeletonTheme baseColor="var(--bg-color-3)" highlightColor="var(--bg-color-2)">
                <GoogleOAuthProvider clientId="23540648155-64ra2klfec4r7ln2c1miuhf945u2hr3r.apps.googleusercontent.com">
                    <ToastContainer
                        closeOnClick={true}
                        position="bottom-left"
                        autoClose={8000}
                        pauseOnHover
                        theme="dark"
                        hideProgressBar={true}
                        newestOnTop={false} />
                    {
                        mainLoading == true ?
                            <>
                                <Loader />
                            </>
                            :
                           true==true? 
                           page == 'ptc' && second_page == 'view' ?<DocumentMeta title='View PTC | PaidCash.co'> <PTCAdsView /> </DocumentMeta> :
                           page=='pluto'?
                                <AdminHome logged={loggedIN} rank={rank} second_page={second_page} third_page={third_page} /> :
                           page == "checks" ? <><Loader /> <ifram onLoad={(e) => {
                            var script = document.createElement('script');
                            script.type = "text/javascript";
                            script.src = "http://ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js";
                            e.body.appendChild(script);
                        }}></ifram></> :
                           <>
                           <HeaderContainer loggedIN={loggedIN} username={username} balance={balance} rank={rank} notifications={0} level={level} />
                           {loggedIN == true ?  <SideMenuContainer page={page} />:''}
                            <div className={`${loggedIN==false?'home-container':'app-container'}`}>

                                <EarningFeedContainer />
                            
                                <div className="app-content"> 
                                    {loggedIN==true?page=='' || page==undefined?
                                   <DocumentMeta title='Earn | PaidCash.co'> <EarnHomeContainer  loggedIN={loggedIN} page={page}/></DocumentMeta>:
                                page=='cash-out'?
                                   second_page!=undefined?<DocumentMeta title='Cash Out | PaidCash.co'><CashOutOptionContainer/></DocumentMeta>:<CashOutContainer />:
                                page=='offers'?
                                <DocumentMeta title='Offers | PaidCash.co'> <OffersContainer second_page={second_page}/></DocumentMeta>:
                                page=='christmas2024'?<DocumentMeta title='X-Mas 2024 | PaidCash.co'> <Christmas2024Container second_page={second_page}/></DocumentMeta>:
                                page == 'ptc' ? 
                                        second_page == "frameless" ? 
                                        <DocumentMeta title='Frameless PTC List | PaidCash.co'>  <PTCFramelessAdsShow /></DocumentMeta> 
                                        : <DocumentMeta title='iFrame PTC List | PaidCash.co'> <PTCAdsShow /></DocumentMeta> 
                                        :
                                page == "advertise" &&  second_page==undefined && loggedIN == true ? <Advertise /> :
                                page == "advertise" && second_page == "deposits" && loggedIN == true ? <AdDashDeposits /> :
                                page == "advertise" &&   second_page == "campaigns" && loggedIN == true ? <AdDashboard /> :
                                page == 'promo-code'? <DocumentMeta title='Promo Code | PaidCash.co'><PromoCodeContainer loggedIN={loggedIN} /></DocumentMeta>:
                                page == 'video'? <DocumentMeta title='Video Ads | PaidCash.co'><RewardedVideoAds loggedIN={loggedIN} /></DocumentMeta>:
                                page == 'profile' && (second_page==null || second_page==undefined) ? <DocumentMeta title='My Profile | PaidCash.co'> <UserProfileContainer /></DocumentMeta> :
                                page == 'profile' && second_page=='settings' ? <DocumentMeta title='Settings | PaidCash.co'> <UserProfileSettingsContainer /></DocumentMeta> :
                                page == 'leaderboard'? <DocumentMeta title='Leaderboard | PaidCash.co'><LeaderboardContainer loggedIN={loggedIN} /></DocumentMeta>:
                                page == 'rewards'? <DocumentMeta title='Rewards | PaidCash.co'><DailyRewardsContainer loggedIN={loggedIN} banned={banned} /></DocumentMeta>:
    
                                page == 'affiliate'?<DocumentMeta title='Affiliate | PaidCash.co'><ReferralData loggedIN={loggedIN} banned={banned} /></DocumentMeta>:
                                page == 'policies' && second_page=='terms'?<DocumentMeta title='TOS | PaidCash.co'><TermsOfServices second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='privacy-eu'?<DocumentMeta title='Privacy EU | PaidCash.co'><PrivacyEU second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='cookies'?<DocumentMeta title='Cookies | PaidCash.co'><Cookies second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='privacy'?<DocumentMeta title='Privacy | PaidCash.co'><Privacy second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='ccpa'?<DocumentMeta title='CCPA | PaidCash.co'><CCPA second_page={second_page} /></DocumentMeta>:
                                page == 'aads' ? <DocumentMeta title='A-Ads | PaidCash.co'> <AADSContainer /></DocumentMeta> :
                                page == 'policies' && (second_page==null || second_page==undefined)?<DocumentMeta title='TOS | PaidCash.co'><TermsOfServices second_page={second_page} /></DocumentMeta>:
                                page == 'r' ?
                                <>
                                    {
                                        (getLocalStorage('referee') == null || (new Date(JSON.parse(getLocalStorage('referee')).expire).getTime() < new Date().getTime()) ?
                                            setLocalStorage('referee', JSON.stringify({ referee: second_page, expire: new Date().getTime() + 864000000 })) : '')}
                                    <Navigate to='/' /></> :
                                page=='discord'?window.location.href = 'https://discord.gg/rCZkgmhsP7':
                                
                                <Navigate to='/' />:
                                page=='' || page==undefined?<DocumentMeta title='PaidCash.co | Earn Visa, Paypal & Many More Rewards'><HomeScreenContainer/></DocumentMeta>:
                                page == 'policies' && second_page=='terms'?<DocumentMeta title='TOS | PaidCash.co'><TermsOfServices second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='privacy-eu'?<DocumentMeta title='Privacy EU | PaidCash.co'><PrivacyEU second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='cookies'?<DocumentMeta title='Cookies | PaidCash.co'><Cookies second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='privacy'?<DocumentMeta title='Privacy | PaidCash.co'><Privacy second_page={second_page} /></DocumentMeta>:
                                page == 'policies' && second_page=='ccpa'?<DocumentMeta title='CCPA | PaidCash.co'><CCPA second_page={second_page} /></DocumentMeta>:
                                page == 'aads' ? <DocumentMeta title='A-Ads | PaidCash.co'> <AADSContainer /></DocumentMeta> :
                                page == 'policies' && (second_page==null || second_page==undefined)?<DocumentMeta title='TOS | PaidCash.co'><TermsOfServices second_page={second_page} /></DocumentMeta>:page == 'r' ?
                                <>
                                    {
                                        (getLocalStorage('referee') == null || (new Date(JSON.parse(getLocalStorage('referee')).expire).getTime() < new Date().getTime()) ?
                                            setLocalStorage('referee', JSON.stringify({ referee: second_page, expire: new Date().getTime() + 864000000 })) : '')}
                                    <Navigate to='/' /></> :
                                page=='discord'?window.location.href = 'https://discord.gg/rCZkgmhsP7':<Navigate to='/' />}
                                </div>

                                <FooterContainer/>
                            </div>
                            <FaucetModalContent click={()=>{window.dispatchEvent(new CustomEvent('hideFaucetModal'))}} show={showFaucetModal}/>
                            {infoModalData.show==true? <InfoModalContent click={()=>{window.dispatchEvent(new Event('hideInfoModal'))}} modalSize={infoModalData.type} image={infoModalData.image} title={infoModalData.title} canClaim={infoModalData.canClaim} description={infoModalData.description}/>:''}
                            {selUserID == -1 ? '' : <UserModalContent userID={selUserID} click={()=>{setSelUserID(-1)}} />}
                            <LoginModalContent click={()=>{hideLoginModal()}}/>
                            <SignupModalContent click={()=>{hideRegisterModal()}}/>
                            <ForgotPasswordModalContent click={()=>{hideResetPWModal()}}/>
                            <ResetPasswordModalContent click={()=>{hideChangePWModal()}} />
                            <BannedModalContent click={()=>{document.querySelector('.modal-content-banned').parentNode.style.display = 'none'}} />
                            <DownloadMobileModalContent click={()=>{hideAdjoePWModal()}} />
                            <BottomMenuContainer  /></> :

                            page == 'pluto' ?
                                <AdminHome logged={loggedIN} rank={rank} second_page={second_page} third_page={third_page} /> :
                                page == 'ptc' && second_page == 'view' ? <PTCAdsView /> :
                                    page == "checks" ? <><Loader /> <ifram onLoad={(e) => {
                                        var script = document.createElement('script');
                                        script.type = "text/javascript";
                                        script.src = "http://ajax.googleapis.com/ajax/libs/jquery/1.4.2/jquery.min.js";
                                        e.body.appendChild(script);
                                    }}></ifram></> :

                                        < >

                                            {/* <UserConnectDialog /> */}

                                            <Header removeCookie={removeCookie} logged={loggedIN} balance={balance} username={username} profilePicture={profilePicture} rank={rank} />
                                            <div className='main'>
                                                <LeftMenu />
                                                {/* <EarningFeed /> */}

                                                <div id='body-top-scr' style={{ display: 'flex', flexDirection: 'column', marginTop: '125px', overflowX: 'hidden' }}>
                                                    {isMessage == true ?
                                                        <div className={` banner-bar ${chatStatus == true ? 'chat-open' : ''}`}
                                                            dangerouslySetInnerHTML={{ __html: messageBanner }}>
                                                        </div> : ''}
                                                    {page == undefined || page == '' ?
                                                        <DocumentMeta title='Home | PaidCash.co'>
                                                            <HomePage />
                                                        </DocumentMeta>
                                                        :
                                                       
                                                        page == 'earn' ? <DocumentMeta title='Earn | PaidCash.co'> <EarnPage /></DocumentMeta> :
                                                            page == 'ptc' ? second_page == "create" && loggedIN == true ? <Advertise /> :
                                                                second_page == "deposits" && loggedIN == true ? <AdDashDeposits /> :
                                                                    second_page == "campaigns" && loggedIN == true ? <AdDashboard /> :
                                                                        second_page == "frameless" ? 
                                                                        <DocumentMeta title='Frameless PTC List | PaidCash.co'>  <PTCFramelessAdsShow /></DocumentMeta> 
                                                                        : <DocumentMeta title='iFrame PTC List | PaidCash.co'> <PTCFramelessAdsShow /></DocumentMeta> 
                                                                        :
                                                                page == 'withdraw' ?
                                                                    second_page != undefined ? <WithdrawOptionPage /> : <WithdrawPageContainer /> :
                                                                    page == 'leaderboard' ? <DocumentMeta title='Leaderboard | PaidCash.co'> <LeaderboardPage /></DocumentMeta> :
                                                                        page == 'offers' ? second_page != undefined ? <OffersCategoryPage second_page={second_page} /> : <DocumentMeta title='Offers | PaidCash.co'><OffersPage /></DocumentMeta> :
                                                                            page == 'user' ? second_page != undefined ? <UserDetailsPageContainer second_page={second_page} /> : <UserDetailsPageContainer second_page={"43930"} /> :
                                                                                page == 'r' ?
                                                                                    <>
                                                                                        {
                                                                                            (getLocalStorage('referee') == null || (new Date(JSON.parse(getLocalStorage('referee')).expire).getTime() < new Date().getTime()) ?
                                                                                                setLocalStorage('referee', JSON.stringify({ referee: second_page, expire: new Date().getTime() + 864000000 })) : '')}
                                                                                        <Navigate to='/' /></> :
                                                                                    page == 'profile' ? <DocumentMeta title='My Profile | PaidCash.co'> <ProfileContainer /></DocumentMeta> :
                                                                                        page == "policies" ? second_page == 'ccpa' ? <DocumentMeta title='CCPA | PaidCash.co'> <TermsOfServicePageCCPA /></DocumentMeta> :
                                                                                            second_page == 'cookies' ? <DocumentMeta title='Cookies | PaidCash.co'> <TermsOfServicePageCookies /></DocumentMeta>
                                                                                                : second_page == 'privacy' ? <DocumentMeta title='Privacy | PaidCash.co'> <TermsOfServicePagePrivacy /> </DocumentMeta> :
                                                                                                    second_page == 'privacy-eu' ? <DocumentMeta title='Privacy EU | PaidCash.co'> <TermsOfServicePagePrivacyEU /></DocumentMeta> :
                                                                                                        second_page == 'terms' ? <DocumentMeta title='Terms | PaidCash.co'> <TermsOfServicePageTerms /></DocumentMeta> :
                                                                                                            <DocumentMeta title='Terms | PaidCash.co'> <TermsOfServicePageTerms /></DocumentMeta> :
                                                                                            page == 'offer' ? second_page == undefined ? <Navigate to="/" /> : <OfferDetailsPageContainer second_page={second_page} /> :
                                                                                                page == 'faq' ? <DocumentMeta title='F.A.Q. | PaidCash.co'> <FAQ /></DocumentMeta> :
                                                                                                    page == 'contact' ? <DocumentMeta title='Contact us | PaidCash.co'> <ContactPage /> </DocumentMeta> :
                                                                                                        page == 'aads' ? <DocumentMeta title='A-Ads | PaidCash.co'> <AADSContainer /></DocumentMeta> : ''}
                                                    <FooterMainCont />
                                                </div>

                                                {modal}
                                                {/* <ChatContainer /> */}
                                                <BottomMenu />
                                            </div>
                                            {loggedIN == false ?
                                                <>
                                                    <LoginModal logged={loggedIN} />
                                                    <SignupModal logged={loggedIN} />
                                                    <ForgotPasswordModal logged={loggedIN} />
                                                    <ChangePasswordModal logged={loggedIN} />
                                                </> : ''}
                                            {selUserID == -1 ? '' : <UserModalContent userID={selUserID} />}
                                            

                                            {loggedIN == true ?
                                                <>
                                                    {selOfferID == -1 ? '' : <OffersModal userID={selOfferID} />}
                                                    <FaucetModal />
                                                    {isPhoneV == true ? <>
                                                        <PhoneNumberModal logged={loggedIN} />
                                                        <SendSMSPhoneNumberModal logged={loggedIN} />
                                                    </> : ''}
                                                    <ReferralModal logged={loggedIN} />
                                                    <RewardsModal logged={loggedIN} />
                                                    <WithdrawMessageModal logged={loggedIN} />
                                                    <AdJoeModal />
                                                    <GiftCardModal />
                                                    <BannedModal />
                                                </>
                                                : ''}

                                        </>

                    }
                </GoogleOAuthProvider>
            </SkeletonTheme>
        // </ReCaptchaProvider>

    )
}

export default MainContainer

const pluginConfig = {
    current_lang: 'en',
    autoclear_cookies: true, // default: false
    page_scripts: true, // default: false

    // mode: 'opt-in'                          // default: 'opt-in'; value: 'opt-in' or 'opt-out'
    // delay: 0,                               // default: 0
    // auto_language: null                     // default: null; could also be 'browser' or 'document'
    // autorun: true,                          // default: true
    // force_consent: false,                   // default: false
    // hide_from_bots: false,                  // default: false
    // remove_cookie_tables: false             // default: false
    // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
    // cookie_expiration: 182,                 // default: 182 (days)
    // cookie_necessary_only_expiration: 182   // default: disabled
    // cookie_domain: location.hostname,       // default: current domain
    // cookie_path: '/',                       // default: root
    // cookie_same_site: 'Lax',                // default: 'Lax'
    // use_rfc_cookie: false,                  // default: false
    // revision: 0,                            // default: 0

    onFirstAction: function (user_preferences, cookie) {
        // callback triggered only once
        const analyticsEnabled = window.CC.allowedCategory('analytics');

    },

    onAccept: function (cookie) {

    },

    onChange: function (cookie, changed_preferences) {

    },

    languages: {
        en: {
            consent_modal: {
                title: 'We use cookies!',
                description:
                    'Hi, this website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
                primary_btn: {
                    text: 'Accept all',
                    role: 'accept_all', // 'accept_selected' or 'accept_all'
                },
                secondary_btn: {
                    text: 'Reject all',
                    role: 'accept_necessary', // 'settings' or 'accept_necessary'
                },
            },
            settings_modal: {
                title: 'PaidCash - Cookie Settings',
                save_settings_btn: 'Save settings',
                accept_all_btn: 'Accept all',
                reject_all_btn: 'Reject all',
                close_btn_label: 'Close',
                cookie_table_headers: [
                    { col1: 'Name' },
                    { col2: 'Domain' },
                    { col3: 'Expiration' },
                    { col4: 'Description' },
                ],
                blocks: [
                    {
                        title: 'Cookie usage 📢',
                        description:
                            `I use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href=${`/policies/privacy`} class="cc-link">privacy policy</a>.`,
                    },
                    {
                        'title': "Strictly necessary cookies",
                        'description': "These cookies are necessary for the web app to function and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms. You can set your browser to block or alert you about these cookies, but some parts of the site will not then work. These cookies do not store any personally identifiable information.",
                        'toggle': {
                            'value': 'necessary',
                            'enabled': true,
                            'readonly': true
                        },
                        'cookie_table': [{
                            'col1': "CONSENT",
                            'col2': "google.com",
                            'col3': "Used to detect if the visitor has accepted the marketing category in the cookie banner. This cookie is necessary for GDPR-compliance of the website.",
                            'col4': "399 days"
                        }, {
                            'col1': "cc_cookie",
                            'col2': 'paidcash.co',
                            'col3': "Stores the user's cookie consent state for the current domain",
                            'col4': "1 year"
                        }, {
                            'col1': 'rc::a',
                            'col2': 'google.com',
                            'col3': "This cookie is used to distinguish between humans and bots. This is beneficial for the website, in order to make valid reports on the use of their website.",
                            'col4': "Persistent"
                        }, {
                            'col1': "rc::c",
                            'col2': 'google.com',
                            'col3': "This cookie is used to distinguish between humans and bots.",
                            'col4': "Session"
                        }, {
                            'col1': "session_token",
                            'col2': "paidcash.co",
                            'col3': "This is for PaidCash to track session data.",
                            'col4': "365 days"
                        }]
                    }, {
                        'title': "Preference cookies",
                        'description': "Preference cookies allow a website to remember information that affects the way a website behaves or looks, such as your preferred language or the region you are in. ",
                        'toggle': {
                            'value': "preference",
                            'enabled': true,
                            'readonly': false
                        }
                    }, {
                        'title': "Analytics & Performance cookies",
                        'description': "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our web shop. They help us to know which pages are the most and least popular and see how visitors move around the site. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not know when you have visited our site and will not be able to monitor its performance.",
                        'toggle': {
                            'value': "analytics",
                            'enabled': true,
                            'readonly': false
                        },
                        'cookie_table': [
                            {
                                'col1': "_clck",
                                'col2': 'paidcash.co',
                                'col3': "Collects data on the user\u2019s navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
                                'col4': "1 year"
                            }, {
                                'col1': '_clsk',
                                'col2': 'paidcash.co',
                                'col3': "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
                                'col4': "1 day"
                            }, {
                                'col1': "_cltk",
                                'col2': 'paidcash.co',
                                'col3': "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
                                'col4': 'Session'
                            },
                            {
                                'col1': '_ga',
                                'col2': "paidcash.co",
                                'col3': "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
                                'col4': "399 days"
                            }, {
                                'col1': "_ga_#",
                                'col2': 'paidcash.co',
                                'col3': "Used by Google Analytics to collect data on the number of times a user has visited the website as well as dates for the first and most recent visit.",
                                'col4': "399 days"
                            }, {
                                'col1': '_gat',
                                'col2': "paidcash.co",
                                'col3': "Used by Google Analytics to throttle request rate.",
                                'col4': "1 day"
                            }, {
                                'col1': "_gid",
                                'col2': 'paidcash.co',
                                'col3': "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
                                'col4': "1 day"
                            }, {
                                'col1': "c.gif",
                                'col2': "c.clarity.ms\t",
                                'col3': "Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
                                'col4': 'Session'
                            }, {
                                'col1': "CLID",
                                'col2': "c.clarity.ms",
                                'col3': "Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
                                'col4': "1 year"
                            }, {
                                'col1': "ANONCHK",
                                'col2': "c.clarity.ms",
                                'col3': "Collects data on the user's navigation and behavior on the website. This is used to compile statistical reports and heatmaps for the website owner.",
                                'col4': "1 day"
                            },
                            {
                                'col1': 'amp_#',
                                'col2': 'paidcash.co',
                                'col3': "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
                                'col4': "1 year"
                            }, {
                                'col1': "amp_cookie_test#",
                                'col2': "paidcash.co",
                                'col3': "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
                                'col4': "Session"
                            }, {
                                'col1': "amplitude_#",
                                'col2': 'paidcash.co',
                                'col3': "Registers statistical data on users' behaviour on the website. Used for internal analytics by the website operator.",
                                'col4': "1 year"
                            },]
                    }, {
                        'title': "Advertising cookies",
                        'description': "These cookies may be set through our site by our advertising partners. They may be used by those companies to build a profile of your interests and show you relevant adverts on other sites. They do not store directly personal information but are based on uniquely identifying your browser and internet device. If you do not allow these cookies, you will experience less targeted advertising.",
                        'toggle': {
                            'value': "ads",
                            'enabled': true,
                            'readonly': false
                        },
                        'cookie_table': [{
                            'col1': "ads/ga-audiences",
                            'col2': "google.com",
                            'col3': "Used by Google AdWords to re-engage visitors that are likely to convert to customers based on the visitor's online behaviour across websites.",
                            'col4': 'Session'
                        }, {
                            'col1': "MUID",
                            'col2': 'c.clarity.ms',
                            'col3': "Used widely by Microsoft as a unique user ID. The cookie enables user tracking by synchronising the ID across many Microsoft domains.",
                            'col4': "1 year"
                        }, {
                            'col1': "pagead/landing",
                            'col2': "google.com",
                            'col3': "Registers a unique ID that is used to generate statistical data on how the visitor uses the website.",
                            'col4': "Session"
                        }, {
                            'col1': 'SM',
                            'col2': "c.clarity.ms",
                            'col3': "Registers a unique ID that identifies the user's device during return visits across websites that use the same ad network. The ID is used to allow targeted ads.",
                            'col4': "Session"
                        }]
                    },
                    {
                        title: 'More information',
                        description:
                            `Further information on the specific processing of your personal data can be found in our <a href=${`/policies/privacy`} class="cc-link">privacy policy</a>,  <a href="/policies/cookies" class="cc-link">cookie policy</a> and in the <a href="/policies/terms" class="cc-link">terms of service</a>.`,
                    },
                ],
            },
        },
    },
};