import { useEffect, useState } from "react"
import Skeleton from "react-loading-skeleton"
import { Link, Navigate, useParams } from "react-router-dom"
import { socket } from "../components/Containers/MainContainer"
import { Popover } from "@headlessui/react"
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import { FaArrowDown, FaArrowUp, FaStar, FaTextHeight } from "react-icons/fa"
import { IoSearchSharp } from "react-icons/io5"
import { ChangeStateUrl, ChangeStateUrlPath, getDevicesSelected } from "../components/utils"
import queryString from "query-string"
import '../assets/style/OffersCont.css'
import { PromotedSmallOfferItem } from "../Component/PromotedSmallOffer/Item.PromotedSmallOffer"
import { PromotedOfferModalContent } from "./ModalContent/PromotedOffer.ModalContent"
import InfiniteScroll from "react-infinite-scroll-component"
import { set } from "nprogress"
import { Loader } from "../components/Loader"

export const OffersContainer = (props) => {
    useEffect(function () {
        document.querySelectorAll('.tippy-popper').forEach(x => x.remove())
        window.scrollTo(0, 0)
    }, [])
const { referral_id, page, second_page, third_page } = useParams();

    const [offerData, setOfferData] = useState(0)
    let params = queryString.parse(window.location.search)
    const [promotedOffers, setPromotedOffers] = useState(new Array(30).fill('cappa'))
    const [selectedSort, setSelectedSort] = useState('Popularity')
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    let networkTimeout = null;
    const [hasMore, setHasMore] = useState(false);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });
    function getName(c) {
        switch (c) {
            case 'quiz':
                return 'Quiz';
                break;
            case 'app':
                return 'App';
                break;
            case 'register':
                return 'Sign Up';
                break;
            case 'trial':
                return 'Free Trial';
                break;
            case 'game':
                return 'Game';
                break;
            case 'purchase':
                return 'Purchase';
                break;
            case 'casino':
                return 'Casino';
                break;
            case 'crypto':
                return 'Crypto';
                break;
            case 'deposit':
                return 'Deposit';
                return;
            case 'other':
                return 'Other';
                break;
            default:
                return 'All';
                break;

        }
    }
    useEffect(() => {
        window.dispatchEvent(new Event('clearOffersPage'))
        setlazyState({ ...lazyState, page: 0 });
        setPromotedOffers(new Array(30).fill('cappa'))
        socket.emit('getListOffers', {
            category: getName(second_page).toLowerCase(),
            page: 0,
            devices: getDevicesSelected(),
            query: params.query,
            sort: selectedSort.includes("Popularity") ? 1 : selectedSort.includes("High to Low") ? 2 : 3
        });
    }, [second_page])
    function handleListOffers() {
        socket.on('emitListOffers', async (data) => {
            let ppOff = [...promotedOffers]; // Keep previous offers

            if (promotedOffers[0] === 'cappa') {
                ppOff = [...data.offers]; // Replace entirely if condition matches
            } else {
                ppOff = [...ppOff, ...data.offers]; // Concatenate new offers to existing
            }
            setLoading(false);
            setTotalRecords(data.pageNumber);
            setPromotedOffers(ppOff); // Update the state with the new array
            socket.off('emitListOffers')

        })
    }
    useEffect(() => {
        window.addEventListener('clearOffersPage', () => {
        setPromotedOffers(new Array(30).fill('cappa'))
        setlazyState({ ...lazyState, page: 0 });
        setPromotedOffers(new Array(30).fill('cappa'))
        handleListOffers()
        })

        socket.emit('getListOffers', {
            category: getName(second_page).toLowerCase(),
            page: lazyState.page,
            devices: getDevicesSelected(),
            query: params.query,
            sort: selectedSort.includes("Popularity") ? 1 : selectedSort.includes("High to Low") ? 2 : 3
        });
        handleListOffers()
        

    }, [])
    const [offerCategories, setOfferCategories] = useState([])
    useEffect(() => {
        socket.emit('fetchCountCategoryOffers', {});
        socket.on('emitCountCategoryOffers', (data) => {
            let count = 0;
            data.offers.forEach(element => {
                count += parseInt(element.count);
            });
            let c = data.offers.reverse();
            c = data.offers.reverse();
            setOfferCategories(c.map(x => { return { name: x.category, count: x.count } }));
            socket.off('emitCountCategoryOffers')
        });
    }, [])
    const [showOfferModal, setShowOfferModal] = useState(false)
    function hideOfferModal(){setShowOfferModal(false)}
    return (
        <InfiniteScroll
        dataLength={promotedOffers.length}
        next={()=>{
           if(promotedOffers.length<=Math.ceil(totalRecords)){
            setlazyState({ ...lazyState, page: lazyState.page + 1 });
            handleListOffers()
            socket.emit('getListOffers', {
                category: getName(second_page).toLowerCase(),
                page: lazyState.page+1,
                devices: getDevicesSelected(),
                query: params.query,
                sort: selectedSort.includes("Popularity") ? 1 : selectedSort.includes("High to Low") ? 2 : 3
            });
            
           }
          
        }}
        hasMore={promotedOffers.length<Math.ceil(totalRecords)}
        loader={'Loading...'}
      >
       <div className="offers-container">
        {showOfferModal==true?<PromotedOfferModalContent offer={offerData} click={hideOfferModal}/>:''}
             <div className='cat-earn-mob'>
                {offerCategories.length == 0 ? <Skeleton width={2000} height={32} /> : [{name:'all'},...offerCategories].map((item, index) => {
                        return (
                            <Link className={`${second_page==undefined?item.name.toLowerCase()=='all'?'sel':'': second_page.toLowerCase().includes(item.name.toLowerCase()) ? 'sel' : ''}`} to={`${(item.name.toLowerCase() == "home" ? 'earn' : item.name.toLowerCase() == "all" ? '/offers' : `/offers/${item.name.toLowerCase().split(" ").join("-")}`)}`} key={index}>
                                <div className={`cat-earn-mob-item `}>
                                    <div className='cat-earn-mob-item-name'>{item.name}</div>
                                </div>
                            </Link>
                        )
                    })
                    }
            </div>
            <div className="offers-search">
                        <div className='offers-search-left' >
                            <h1>{getName(second_page)}</h1>
                            <div className='bonus-code-reward'>
                            <div className='input-container' style={{background:'var(--bg-color-3)'}}>
                                <div className="input-icon"><FaTextHeight/></div>
                                <input onKeyDown={(e) => {
                                    if (e.key == 'Enter') {
                                        <Navigate to={`/offers?query=${document.getElementById('searchOffer').value}`} />
                                        // ChangeStateUrlPath(`/offers?query=${document.getElementById('searchOffer').value}`)
                                        window.dispatchEvent(new Event('clearOffersPage'))

                                        socket.emit('getListOffers', {
                                            category: getName('').toLowerCase(),
                                            page: 0,
                                            devices: getDevicesSelected(),
                                            query: document.getElementById('searchOffer').value,
                                            sort: document.getElementById('searchOffer').value.includes("Popularity") ? 1 : document.getElementById('searchOffer').value.includes("High to Low") ? 2 : 3
                                        });
                                        document.getElementById('searchOffer').value = ''
                                    }
                                }} defaultValue={params.query} id="searchOffer" className='bonus-code-reward-input' type="text" placeholder='Search offer..' />
                                <div className="input-icon">
                                <IoSearchSharp style={{ cursor: 'pointer' }} onClick={() => {

// ChangeStateUrlPath(`/offers?query=${document.getElementById('searchOffer').value}`)
window.dispatchEvent(new Event('clearOffersPage'))

socket.emit('getListOffers', {
    category: getName('').toLowerCase(),
    page: 0,
    devices: getDevicesSelected(),
    query: document.getElementById('searchOffer').value,
    sort: selectedSort.includes("Popularity") ? 1 : selectedSort.includes("High to Low") ? 2 : 3
});
document.getElementById('searchOffer').value = ''

}} />
                                </div>
                            </div>
                              
                            </div>

                           

                        </div>
                        {/* <div className="offers-cont-sort">
                            Sort by
                            <Popover style={{ position: 'relative' }}>
                                {({ open }) => (
                                    <>
                                        <Popover.Button style={{ border: 'none', background: 'transparent' }}>
                                            <div className="deviceSelectorBTN" id="sortTextID" style={{ marginTop: '-1px' }}>
                                                {selectedSort} {selectedSort.includes("Popularity") ? <FaStar /> : selectedSort.includes("High to Low") ? <FaArrowUp /> : <FaArrowDown />}
                                                {open == true ? <MdOutlineArrowDropUp style={{ color: '#fff', marginTop: '0px' }} /> : <MdOutlineArrowDropDown style={{ color: '#fff', marginTop: '0px' }} />}
                                            </div></Popover.Button>
                                        <Popover.Panel className="sort-menu-box ">
                                            {({ close }) => (
                                                <>
                                                    {['Popularity', 'Payout Low to High', 'Payout High to Low'].map((x, i) => {
                                                        return (
                                                            <div onClick={() => {
                                                                if (selectedSort != x) {
                                                                    close()

                                                                    setSelectedSort(x)
                                                                    window.dispatchEvent(new Event('clearOffersPage'))

                                                                    socket.emit('getListOffers', {
                                                                        category: getName(second_page).toLowerCase(),
                                                                        page: 0,
                                                                        devices: getDevicesSelected(),
                                                                        query: params.query,
                                                                        sort: selectedSort.includes("Popularity") ? 1 : selectedSort.includes("Low to High") ? 2 : 3
                                                                    });
                                                                } else {
                                                                    close()

                                                                }

                                                            }} className="header-menu-box-item show-usd" style={{ border: 'none' }}>
                                                                <p style={{ border: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '3px' }}>{x} {x.includes("Popularity") ? <FaStar /> : x.includes("High to Low") ? <FaArrowUp /> : <FaArrowDown />}</p>
                                                            </div>
                                                        )
                                                    })}


                                                </>
                                            )}
                                        </Popover.Panel>
                                    </>
                                )}
                            </Popover>
                        </div> */}
            </div>
            <div>
          
               <div className="offers-cont-list">

                    {promotedOffers.map((item, i) => {
                          if (item.name == undefined) {
                            return <Skeleton className='offer-featured-box' style={{ width: '100px', height: '170px', marginRight:'15px', borderRadius: '8px' }} />
                        }else
                        return <div key={i} className='offer-featured-box' onClick={() => {
                            setOfferData(item)
                            setShowOfferModal(true)
                            
                        }}>
                            <PromotedSmallOfferItem
                            image={item.picture+''}
                            title={item.name+''}
                            description={item.description!=undefined?item.description:item.name}
                            amount={item.reward+''}
                            category={item.category+''}
                            platforms={item.platforms+''}
                            locked={false}
                            lockedText={'Locked'}

                        />
        </div>})}
        </div>
            </div>
                    
       </div>
       </InfiniteScroll>

    )
}