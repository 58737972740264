import { Popover } from "@headlessui/react"
import { FaAngleDown, FaAngleUp, FaApple, FaLaptop, FaLevelUpAlt, FaPlane, FaTicketAlt, FaUser, FaUserAlt, FaUserCheck, FaUsers } from "react-icons/fa"
import { RiLogoutBoxLine } from "react-icons/ri"
import ProgressiveImage from "react-progressive-image-loading"
import { Link, Navigate, useParams } from "react-router-dom"
import { getDevicesSelected, getShownCurency, numberWithCommas, setDevicesSelected, setShownCurrency } from "../../components/utils"
import { SideMenuButtonHeader } from "./SideMenuButton.Header"
import { MenuButtonSideMenu } from "../SideMenu/MenuButton.SideMenu"
import { BiDevices, BiSolidUserRectangle } from "react-icons/bi"
import { IoIosSettings, IoLogoAndroid } from "react-icons/io"
import { GiMoneyStack } from "react-icons/gi"
import { PiDevicesFill } from "react-icons/pi"
import { useState } from "react"
import { MdOutlineArrowDropDown, MdOutlineArrowDropUp } from "react-icons/md"
import { socket } from "../../components/Containers/MainContainer"


export const ProfileHeader = (props) => {
  const [devicesSelected, setDevicesSelectedX] = useState(getDevicesSelected())
  const { referral_id, page, second_page, third_page } = useParams();

    return (
        <div className="header-profile">
             <Popover className="profile-selector">
                {({ open }) => (
                  <>
                    <Popover.Button style={{ background: 'none', padding: '0px', border: 'none' }}>
                    <img alt="offer image" className="header-profile-picture" 
                loading="lazy" src={`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${props.username}`} />
                        {open == false ?   
            <div className="header-profile-dropdown"><FaAngleDown /></div> : 
            <div className="header-profile-dropdown"><FaAngleUp /></div>}
                        </Popover.Button>
                    <Popover.Panel className="header-menu-box ">
                      {({ close }) => (
                        <>
                          {window.addEventListener("closeProfileSelector", () => {
                            close()
                          })}
                         <div className="header-menu-box-profile-container">
                            <ProgressiveImage
                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                src={`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${props.username}`}
                                render={(src, style) => <img alt="offer image" className="header-profile-picture" style={style}
                                    loading="lazy" src={src} />}
                            />
                            <div className="header-menu-box-profile-info-level">Lvl {props.level}</div>

                            <div className="header-menu-box-profile-info">
                                <div className="user-modal-content-header-left-info-name">
                                    {/* <div className="user-modal-content-header-left-rank-badge">
                                        <img src='https://kash.gg/images/assets/ranks/small/bronze-2.webp'/>
                                    </div> */}
                                    {props.username}
                                </div>
                                
                            </div>

                         </div>
                          <MenuButtonSideMenu x={true}  link={true} to={'/profile'} icon={<FaUserCheck/>} title="Profile"/>
                          <MenuButtonSideMenu x={true} link={true} to={'/affiliate'} icon={<FaUsers/>} title="Affiliate"/>
                          {props.rank==undefined ?'': props.rank<=0?'': <MenuButtonSideMenu x={true} link={true} to={'/pluto'} icon={<FaPlane/>} title="Pluto"/>}
                          <Popover style={{ position: 'relative' }} className="device-selector">
            {({ open }) => (
              <>


                <Popover.Button style={{ background: 'none', padding: '0px', border: 'none', width:'100%', borderTop:'0px'}}>
                  <div className="deviceSelectorBTN">
                  <MenuButtonSideMenu x={true} click={()=>{}} icon={<PiDevicesFill/>} title="Devices"/>

                  </div></Popover.Button>
                <Popover.Panel  className="devices-menu-box ">
                  {({ close }) => (
                    <>
                      {window.addEventListener("closeDeviceSelector", () => {
                        close()
                      })}
                      {['windows', 'ios', 'android'].map(x => {
                        let devicesSel = devicesSelected;
                        return (
                          <div className="header-menu-box-item show-usd"><p>{x}</p>
                            <div className="toggle-switch">
                              <input defaultChecked={devicesSel.includes(x)} onChange={(e) => {


                                if (getDevicesSelected().includes(x) == true) {
                                  if (getDevicesSelected().length == 1) {
                                    e.currentTarget.checked = true;
                                  } else {
                                    setDevicesSelected(getDevicesSelected().filter(y => y != x))
                                    setDevicesSelectedX([...getDevicesSelected()])
                                    if (page == 'offers') {
                                      window.dispatchEvent(new Event('clearOffersPage'))

                                      socket.emit('getListOffers', {
                                        category: getName(second_page).toLowerCase(),
                                        page: 0,
                                        devices: getDevicesSelected(),
                                        query: parseQuery(window.location.search).query,
                                        sort: document.querySelector('#sortTextID').innerText.includes("Popularity") ? 1 : document.querySelector('#sortTextID').innerText.includes("High to Low") ? 2 : 3
                                      });
                                    } else if (page == 'earn' || page == '' || page == undefined) {
                                      socket.emit('getPromotedSmallOffers', { devices: getDevicesSelected() });
                                      socket.emit('getPromotedBigOffers', { devices: getDevicesSelected() });
                                      socket.emit('getPromotedFastOffers', { devices: getDevicesSelected() });
                                    }
                                  }
                                } else {
                                  setDevicesSelected([...getDevicesSelected(), x])
                                  setDevicesSelectedX([...getDevicesSelected()])
                                  if (page == 'offers') {
                                    window.dispatchEvent(new Event('clearOffersPage'))

                                    socket.emit('getListOffers', {
                                      category: getName(second_page).toLowerCase(),
                                      page: 0,
                                      devices: getDevicesSelected(),
                                      query: parseQuery(window.location.search).query,
                                      sort: document.querySelector('#sortTextID').innerText.includes("Popularity") ? 1 : document.querySelector('#sortTextID').innerText.includes("High to Low") ? 2 : 3

                                    });

                                  } else if (page == 'earn' || page == '' || page == undefined) {
                                    socket.emit('getPromotedSmallOffers', { devices: getDevicesSelected() });
                                    socket.emit('getPromotedBigOffers', { devices: getDevicesSelected() });
                                    socket.emit('getPromotedFastOffers', { devices: getDevicesSelected() });
                                  }

                                }

                              }} className="toggle-input" id={`${x}-device-sel`} type="checkbox" />
                              <label className="toggle-label" for={`${x}-device-sel`}></label>
                            </div>

                          </div>
                        )
                      })}


                    </>
                  )}
                </Popover.Panel>
              </>
            )}
          </Popover>
          <MenuButtonSideMenu x={true} link={true} to={'/promo-code'} icon={<GiMoneyStack/>} title="Promo Code"/>
                          <MenuButtonSideMenu x={true} link={true} to={'/profile/settings'} icon={<IoIosSettings/>} title="Settings"/>
                          <MenuButtonSideMenu x={true} click={()=>{window.dispatchEvent(new CustomEvent('loggedOut'))}} icon={<RiLogoutBoxLine/>} title="Log out"/>

                          {/* <div className="header-menu-box-item" onClick={(e) => {
                            window.dispatchEvent(new Event("loggedOut"))
                          }}><RiLogoutBoxLine /> Log out</div> */}
                          {/* <div className="header-menu-box-item show-usd"><p>Show USD</p>
                            <div className="toggle-switch">
                              <input onChange={(e) => {
                                if (getShownCurency() == "coin") {
                                  setShownCurrency('usd')
                                } else {
                                  setShownCurrency('coin')
                                }
                              }} className="toggle-input" defaultChecked={getShownCurency() == "coin" ? false : true} id="toggle" type="checkbox" />
                              <label className="toggle-label" for="toggle"></label>
                            </div> */}

                          {/* </div> */}
                        </>
                      )}
                    </Popover.Panel>
                  </>
                )}
              </Popover>
         
        </div>
    )
}
function parseQuery(queryString) {
  var query = {};
  var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }
  return query;
}

function getName(c) {
  switch (c) {
    case 'quiz':
      return 'Quiz';
      break;
    case 'app':
      return 'App';
      break;
    case 'register':
      return 'Sign Up';
      break;
    case 'trial':
      return 'Free Trial';
      break;
    case 'game':
      return 'Game';
      break;
    case 'purchase':
      return 'Purchase';
      break;
    case 'casino':
      return 'Casino';
      break;
    case 'crypto':
      return 'Crypto';
      break;
    case 'deposit':
      return 'Deposit';
      return;
    case 'other':
      return 'Other';
      break;
    default:
      return 'All';
      break;

  }
}
