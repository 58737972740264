
import { useEffect } from "react"
import ProgressiveImage from "react-progressive-image-loading"
import $ from 'jquery';

export function Loader() {
    useEffect(() => {
        window.addEventListener('end_loading', () => {
            $('#loader').fadeOut('slow')
        })
    }, [])
    return (
        <div style={{ position: 'relative', zIndex: '20', width: '100vw' }} bis_skin_checked="1">
            <div id="loader" className="valign-wrapper" bis_skin_checked="1">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap:'20px', flexDirection: 'column', width: '100vw', height:'100vh' }} bis_skin_checked="1">
                    <ProgressiveImage
                        preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                        src="/assets/img/faucetify.png"
                        render={(src, style) => <img id="loader-logo" className="loaderLogo animate__animated animate__bounceIn" height={50} src={src} />}
                    />
                    <div className="lds-ring" style={{ }}>
                        <div>
                        </div>
                        <div>
                        </div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    )
}