'use client'
import { socket } from '../Containers/MainContainer';
import { canUsePagination, numberWithCommas } from '../utils';
import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import SweetAlert from 'react-bootstrap-sweetalert';
import PlutoMenu from './PlutoMenu';
import PaginationPluto from './Pagination';
import VerifyAdmin from './PlutoVerify';

function PlutoSuspectOffers() {
    const [userProfile, setUserProfile] = useState({})
    const [earnings, setEarnings] = useState([0, 0, 0, 0, 0]);
    const [page, setPage] = useState(1)
    const [pageNumber, setPageNumber] = useState(-1)
    const [userId, setUserId] = useState(0);
    const [canUse, setCanUse] = useState(true);
    const [username, setUsername] = useState('')
    const [condition, setCondition] = useState(0)
    const [conditionReason, setConditionReason] = useState('')
    const [rank, setRank] = useState(0)
    useEffect(() => {
        socket.on('response_get_admin_bad_offers', (data) => {
            setEarnings(data.withdrawals)
            setPageNumber(Math.ceil(data.withdrawalsLength / 5))
            setUserProfile(data)
            canUsePagination(true)
            setRank(data.rank)

        })
        socket.emit('getSuspectOffersAdmin', {
            offers_like: username,
            page,
        })
    }, [])
    useEffect(() => {
        socket.on('reTakePendingData', () => {
            socket.emit('getSuspectOffersAdmin', {
                offers_like: username,

                page,
            })
        })
    }, [])
    const [alert, setAlert] = useState({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1, req_hold:0.4 })
    const [alert2, setAlert2] = useState({ show: false, type: '', flagReason: '', code: '', pin: 0 })

    return (
        <SkeletonTheme baseColor="#0d131c" highlightColor="#111923">
            <SweetAlert
                show={alert2.show}
                onConfirm={function () {
                    socket.emit('adminAction', {
                        action: 'delete_pending_offer',
                        id: alert.id
                    })
                    setAlert2({ show: false })
                    setAlert({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 })
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Delete"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert2({ show: false }) }}
            >
                <>
                    <h3>Are you sure that you want to delete {alert.code}#{alert.id}?</h3>
                </>

            </SweetAlert>
            <SweetAlert
                show={alert.show}
                title={alert.title}
                onConfirm={function () {
                    if (alert.code.split("").length > 2 && alert.pin >= -1 && alert.req_hold>=0.01 && alert.req_hold<=1) {
                        if (alert.type == "add") {
                            socket.emit('adminAction', {
                                action: 'add_pending_offer',
                                name: alert.code,
                                days: alert.pin,
                                condition: condition,
                                condition_action: conditionReason,
                            })
                            setAlert({ ...alert, show: false })
                        } else if (alert.type == "edit") {
                            socket.emit('adminAction', {
                                action: 'edit_pending_offer',
                                id: alert.id,
                                name: alert.code,
                                days: alert.pin,
                                req_hold: alert.req_hold,
                                condition: condition,
                                condition_action: conditionReason,
                            })
                            setAlert({ ...alert, show: false })
                        }
                    }
                }}
                style={{ backgroundColor: '#3a3a3a', color: '#f3f3f3', }}
                cancelBtnText="Not now"
                confirmBtnText="Proceed"
                showCancel={true}
                cancelBtnStyle={{ color: '#c1c1c1', textDecoration: 'none' }}
                confirmBtnStyle={{ border: 'none', boxShadow: 'none', padding: '5px 15px', backgroundColor: '#242424' }}
                onCancel={function () { setAlert({ show: false, type: '', flagReason: '', code: '', pin: '', id: -1 }) }}
            >
                <>
                    <h3>Add New Pending Offer:</h3>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={alert.code || ""} placeholder={alert.code || "Offer Name"} type="text" onKeyUp={(e) => {
                        setAlert({ ...alert, code: e.target.value.toLowerCase() })
                    }}></input>
                    <p>Name: {alert.code}</p>
                    <select class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={alert.pin || ""} placeholder={alert.pin || "Condition"} type="text" onKeyUp={(e) => {
                        setAlert({ ...alert, pin: e.target.value })
                    }} onChange={(e) => {
                        setAlert({ ...alert, pin: e.target.value })
                    }}>
                        <option value={0}>0</option>
                        <option value={7}>7</option>
                        <option value={15}>15</option>
                        <option value={30}>30</option>
                        <option value={45}>45</option>
                        <option value={60}>60</option>
                        <option value={90}>90</option>
                    </select>
                    <p>Pending Days: {alert.pin}</p>
                   
                    <select class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={condition || ""} placeholder={condition || "Condition"} type="text" onKeyUp={(e) => {
                        setCondition(e.target.value )
                    }} onChange={(e) => {
                        setCondition( e.target.value )
                    }}>
                        <option value={0}>None</option>
                        <option value={1}>Minutes</option>
                    </select>
                    <p>Condition: {condition}</p>
                    <input class="form-control" style={{ marginBottom: '10px', color: '#fff', background: 'transparent' }} defaultValue={conditionReason} type="text" onKeyUp={(e) => {
                        setConditionReason(e.target.value.toLowerCase())
                    }}></input>
                    <p>Condition Reason: {conditionReason}</p>

                  

                    {alert.type == "edit" ? <div onClick={() => {
                        setAlert({ ...alert, show: false });
                        setAlert2({ ...alert, show: true });

                    }} style={{ margin: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}><div style={{ width: '200px', cursor: 'pointer', background: 'red' }}>Delete</div></div> : ''}
                </>

            </SweetAlert>
            <div class="modal-body" style={{ color: 'var(--text-primary)' }}>
                <div className="user-details-stats">

                    {userProfile.withdrawalsLength != undefined ?
                        <div className="card">
                            <p>
                                {numberWithCommas(userProfile.withdrawalsLength)}
                            </p>
                            <p>Count offers</p>
                        </div>
                        : <Skeleton style={{ width: '136px', height: '50px' }} />}


                </div>
                <div style={{ overflow: 'auto', margin: '0 auto', padding: '20px', marginBottom: '20px', background: 'var(--accent-1)' }}>
                    <p style={{ color: 'var(--text-primary)', fontWeight: 'bold' }}>Suspect Offers</p>
                    <div onClick={() => {
                        setAlert({ ...alert, show: true, type: 'add', id: -1 })
                    }} className="btn button-disabled" style={{ minWidth: '100px', height: '15px', fontSize: '10px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px', marginBottom: '5px', }} >Add new</div>
                    <div className="input-container">
                        <input style={{ borderRadius: '5px', background: 'transparent', marginBottom: '5px', color: '#fff', fontSize: '12px', padding: '5px' }} onChange={(e) => {
                            setUsername(e.currentTarget.value)
                            setEarnings([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])

                            socket.emit('getSuspectOffersAdmin', {
                                offers_like: e.currentTarget.value.toLowerCase(),
                                page: 1,
                            })

                        }} type="text" name="amount" placeholder="Search by offer name" autoComplete="off" />
                    </div>
                    <div style={{ fontWeight: 'bold' }}>
                        <div className="user-details-table-elements" >

                            <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >Name</div>
                            <div style={{ minWidth: '200px' }}>Reason</div>
                            <div style={{ minWidth: '90px' }}>Hold Time</div>
                            <div style={{ minWidth: '50px' }}>Hold</div>
                            <div style={{ minWidth: '50px' }}>Active</div>
                            <div style={{ minWidth: '50px' }}>Pending Method</div>
                            <div style={{ minWidth: '50px' }}>Pending Condition</div>

                            <div style={{ minWidth: '100px', maxWidth: '100px' }}>Edit</div>

                        </div>
                    </div>

                    {earnings.map((earning, i) => {
                        return (
                            <div key={earning.id} className="user-details-table-elements admin-a" style={{ color: 'var(--text-primary)' }}>

                                <div style={{ minWidth: '250px', textAlign: 'left', color: 'var(--text-primary)' }} >{earning.name || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '200px' }} >{earning.reason || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '90px', }} >{earning.pending_time || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.hold || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.active || <Skeleton style={{ width: '30px' }} />}</div>
                                <div style={{ minWidth: '50px', }} >{earning.codition}</div>
                                <div style={{ minWidth: '50px', }} >{earning.codition_action }</div>

                                <div onClick={() => {
                                    setCondition(earning.codition)
                                    setConditionReason(earning.codition_action)
                                    setAlert({ ...alert,req_hold:earning.req_hold, code: earning.name, pin: earning.pending_time.split(" days").join(""), show: true, type: 'edit', id: earning.id })
                                }} className="btn button-disabled" style={{ minWidth: '100px', height: '30px', display: 'flex', alignItems: 'center', justifyContent: 'center', minWidth: '100px', maxWidth: '100px' }} >{earning.id == undefined ? <Skeleton style={{ width: '30px' }} /> : 'Edit'}</div>

                            </div>
                        )
                    })}
                </div>
                {pageNumber == -1 ? <Skeleton style={{ width: '100%' }} /> : <PaginationPluto pageNumber={pageNumber} requestNewList={(page) => {
                    canUsePagination(false)
                    setEarnings([0, 0, 0, 0, 0])
                    socket.emit('getSuspectOffersAdmin', {
                        offers_like: username,

                        page,
                    })
                }} />}

            </div>

        </SkeletonTheme>
    )
}

export default PlutoSuspectOffers