import { FaDollarSign, FaHackerrank, FaTicketAlt } from "react-icons/fa"
import { ModalContent } from "./ModalContent"
import ProgressiveImage from "react-progressive-image-loading"
import { DataTable } from "primereact/datatable"
import { useEffect, useState } from "react"
import { Column } from "primereact/column"
import UserIDProfileModalContainerDTImg from "../../components/earn/UserIDProfileModalContainerDTImg"
import { socket } from "../../components/Containers/MainContainer"
import { byIso } from "country-code-lookup"
import { ChangeStateUrl, ChangeStateUrlPath, numberWithCommas } from "../../components/utils"
import Skeleton from "react-loading-skeleton"
import { set } from "nprogress"

export const UserModalContent = (props) => {
    const [isOpen, setIsOpen] = useState(true)
    const [userDetails, setUserDetails] = useState({})
    const [country, setCountry] = useState('EU')
    const [countryFlag, setCountryFlag] = useState('')
    const [userEarnings, setUserEarnings] = useState([])
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(null);
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCustomers, setSelectedCustomers] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 0,
        sortField: null,
        sortOrder: null,
        filters: {
            name: { value: '', matchMode: 'contains' },
            'country.name': { value: '', matchMode: 'contains' },
            company: { value: '', matchMode: 'contains' },
            'representative.name': { value: '', matchMode: 'contains' }
        }
    });

    let networkTimeout = null;

    useEffect(() => {
        loadLazyData();
    }, [lazyState]);

    const loadLazyData = () => {
        setLoading(true);

        if (networkTimeout) {
            clearTimeout(networkTimeout);
        }
        listenEmittersEarnings()
        socket.emit('getUserEarnings', {
            user: props.userID,
            page: lazyState.page + 1,
            category: 0,
        })


    };
    useEffect(() => {

        socket.on('userDetails', (data) => {
            if (data.userDetails.country != undefined) {
                setCountry(byIso(data.userDetails.country).country)
            }
            setCountryFlag(data.userDetails.country)
            setUserDetails(data.userDetails)
            // console.log(data.userDetails)
            // document.title = `${data.userDetails.username}'s Profile | PaidCash.co`
            // ChangeStateUrlPath(`/user/${props.userID}`)
            socket.off('userDetails')
            // setUserProfile({ ...data.userDetails })
        })
        socket.on('userEarnings', (data) => {
            setUserEarnings(data.earnings)
            setTotalRecords(data.pages * 5)
            setLoading(false);
            socket.off('userEarnings')
            // setEarnings(data.earnings)
            // setPageNumber(data.pages)
            // canUsePagination(true)

        })
        socket.emit('getUserDetails', {
            user: props.userID,
        })
        socket.emit('getUserEarnings', {
            user: props.userID,
            page: 1,
            category: 0,
        })

    }, [])
    function listenEmittersEarnings() {

        socket.on('userEarnings', (data) => {
            setUserEarnings(data.earnings)
            setTotalRecords(data.pages * 5)
            setLoading(false);
            socket.off('userEarnings')

            // setEarnings(data.earnings)
            // setPageNumber(data.pages)
            // canUsePagination(true)

        })
    }

    const onSort = (event) => {
        setlazyState(event);
    };

    const onFilter = (event) => {
        event['first'] = 0;
        setlazyState(event);
    };

    const onSelectionChange = (event) => {
        const value = event.value;

        setSelectedCustomers(value);
        setSelectAll(value.length === totalRecords);
    };

    const onSelectAllChange = (event) => {
        const selectAll = event.checked;

        if (selectAll) {
            userEarnings.getCustomers().then((data) => {
                setSelectAll(true);
                setSelectedCustomers(data.customers);
            });
        } else {
            setSelectAll(false);
            setSelectedCustomers([]);
        }
    };
    const onPage = (event) => {
        setlazyState(event);
    };
    const [path, setPath] = useState('')
    useEffect(() => {
        setPath(window.location.pathname)
        ChangeStateUrlPath(`${window.location.pathname==undefined?'':'/'}user/` + props.userID)
        setIsOpen(true)

            ;
    }, [])
    return (
        <ModalContent
            click={()=>{

                props.click()
                ChangeStateUrlPath(path)
            }}
            title={'User'}
            className={`modal-content-user`}
            hideTitle={true}
            content={
                <>
                    <div className="user-modal-content">
                        <div className="user-modal-content-header">
                            <div className="user-modal-content-header-left">
                                <div className="user-modal-content-header-left-image">
                                {userDetails.username ==undefined ?<Skeleton  height={100} width={100}  style={{borderRadius:'50%'}}/>: 
                                <img src={`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${userDetails['username']}`} alt="Earning Feed Item" />}
                                   
                                </div>
                                <div className="user-modal-content-header-left-info">
                                    <div className="user-modal-content-header-left-info-joined"> {userDetails.profileVisible==true?'Join date is Private':userDetails.joined == undefined ?<Skeleton height={20} width={150} />:userDetails['joined']}</div>
                                    <div className="user-modal-content-header-left-info-name">
                                        {/* <div className="user-modal-content-header-left-rank-badge">
                                        {userDetails.username == undefined ?'': <img src='https://kash.gg/images/assets/ranks/small/bronze-2.webp'/>}
                                        </div> */}
                                        {userDetails.username == undefined ?<Skeleton style={{marginLeft:'-10px'}}  height={20} width={150} />:userDetails['username']}
                                    </div>
                                    <div className="user-modal-content-header-left-info-line">
                                    {userDetails.profileVisible==true?'Level Private':userDetails.level == undefined ?<Skeleton height={20} width={80} />: <div className="user-modal-content-header-left-info-level"> Level {numberWithCommas(userDetails['level'])}</div>}
                                    {userDetails.level == undefined ?'':    <ProgressiveImage
                                            preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryFlag}.svg`}
                                            render={(src, style) => <img className="user-modal-content-header-left-country"  src={src} />}
                                        />}
                                        
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="user-modal-content-header-mid">
                            
                            <div className="user-modal-content-header-mid-section">
                                <div className="user-modal-content-header-section-title">
                                    Earnings
                                </div>
                                <div className="user-modal-content-header-mid-section-items">
                                    <div className="user-modal-content-header-mid-section-item">
                                        <div className="user-modal-content-header-mid-section-item-data">
                                            Coins earned
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.profileVisible==true?'Private':userDetails.coinsEarned == undefined ?<Skeleton height={20} width={80} />: 
                                        <><ProgressiveImage  preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                            src="/assets/img/faucetify_coin_small.png"
                                            render={(src, style) => <img alt="coin image" className="header-balance-icon" style={{marginBottom:'3.5px', marginRight:'1px'}}
                                                loading="lazy" src={src} />}
                                             />  {numberWithCommas(userDetails['coinsEarned'])}</>}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                            Tickets Earned
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.profileVisible==true?'Private':userDetails.ticketsEarned == undefined ?<Skeleton height={20} width={80} />:<>
                                        <FaTicketAlt  className="ticket-design"/> 
                                         {numberWithCommas(userDetails['ticketsEarned'])}
                                        </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-modal-content-header-mid-section">
                                <div className="user-modal-content-header-section-title">
                                    Stats
                                 </div>
                                <div className="user-modal-content-header-mid-section-items-group">

                                 <div className="user-modal-content-header-mid-section-items">
                                    <div className="user-modal-content-header-mid-section-item">
                                        <div className="user-modal-content-header-mid-section-item-data">
                                            Offers Completed
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.profileVisible==true?'Private':userDetails.offersCompleted == undefined ?<Skeleton height={20} width={80} />:numberWithCommas(userDetails['offersCompleted'])}
                                        </div>
                                    </div>
                                    <div className="user-modal-content-header-mid-section-item">
                                         <div className="user-modal-content-header-mid-section-item-data">
                                            Surveys Completed
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item-title">
                                        {userDetails.profileVisible==true?'Private':userDetails.surveysCompleted == undefined ?<Skeleton height={20} width={80} />:numberWithCommas(userDetails['surveysCompleted'])}
                                        </div>
                                    </div>
                                </div>
                                    <div className="user-modal-content-header-mid-section-items">
                                        <div className="user-modal-content-header-mid-section-item">
                                            <div className="user-modal-content-header-mid-section-item-data">
                                                Games Played
                                            </div>
                                            <div className="user-modal-content-header-mid-section-item-title">
                                            {userDetails.profileVisible==true?'Private':userDetails.gamesPlayed == undefined ?<Skeleton height={20} width={80} />:numberWithCommas(userDetails['gamesPlayed'])}
                                            </div>
                                        </div>
                                        <div className="user-modal-content-header-mid-section-item">
                                            <div className="user-modal-content-header-mid-section-item-data">
                                                Users Invited
                                            </div>
                                            <div className="user-modal-content-header-mid-section-item-title">
                                            {userDetails.profileVisible==true?'Private':userDetails.usersReferred == undefined ?<Skeleton height={20} width={80} />:numberWithCommas(userDetails['usersReferred'])}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>  
                        </div>
                        <div className="user-modal-content-header-bottom">
                            <div className="user-modal-content-header-section-title">
                                Activity
                            </div>
                            
                            <DataTable
                                    value={userEarnings} lazy dataKey="id" paginator
                                    first={lazyState.first} rows={5} totalRecords={totalRecords} onPage={onPage}
                                    onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                                    onFilter={onFilter} filters={lazyState.filters} loading={loading} alwaysShowPaginator={false}
                                    selection={userEarnings} onSelectionChange={onSelectionChange} selectAll={selectAll} onSelectAllChange={onSelectAllChange}>
                                    <Column field="name" header="Name" body={(product) => {
                                        return <UserIDProfileModalContainerDTImg image={product.image} name={product.name} wallName={product.wallName} />
                                    }}></Column>
                                    <Column field="reward" header="Reward" body={(product) => {
                                        return <p className="reward-up-modal">

                                             <ProgressiveImage
                                                preview="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
                                                src="/assets/img/faucetify_coin_small.png"
                                                render={(src, style) => <img className="earning-feed-item-reward-image-coin" style={style}
                                                    alt="C" src={src} />}
                                            />
                                            {product.reward}</p>
                                    }}></Column>
                                    <Column field="timeClaimed" header="Time"></Column>
                                </DataTable>
                        </div>
                    </div>
                </>
            }
        />
    )
}