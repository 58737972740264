import { BiSolidHome } from 'react-icons/bi'
import '../assets/style/BottomMenu.css'
import { MenuButtonBottomMenu } from '../Component/BottomMenu/MenuButton.BottomMenu'
import { GiHamburgerMenu, GiPayMoney, GiTakeMyMoney } from 'react-icons/gi'
import { IoIosWallet } from 'react-icons/io'
import { useParams } from 'react-router-dom'

export const BottomMenuContainer = () => {
    function handleMenuClick() {
        document.dispatchEvent(new CustomEvent('handleSideMenuMobile', { detail: { open: true } }))
    }
    const { referral_id, page, second_page, third_page } = useParams();
 
    return (
        <div className="bottom-menu-container">
            <MenuButtonBottomMenu title={"Menu"} icon={<GiHamburgerMenu/>} isLink={false} func={handleMenuClick}/>
            <MenuButtonBottomMenu title={"Rewards"} icon={<GiPayMoney />} to={'/rewards'} selected={page=='rewards'?true:false} isLink={true} />
            <MenuButtonBottomMenu title={"Home"} icon={<BiSolidHome/>} to={'/'} isLink={true}  selected={page=='' || page==undefined?true:false} />
            <MenuButtonBottomMenu title={"Cash Out"} icon={<IoIosWallet />} to={'/cash-out'} isLink={true}  selected={page=='cash-out'?true:false} />
            <MenuButtonBottomMenu title={"Offers"} icon={<GiTakeMyMoney/>} to={'/offers'} isLink={true}  selected={page=='offers'?true:false} />
        </div>
    )
}