import { useEffect, useState } from 'react'
import '../assets/style/EarningFeed.css'
import { ItemEarningFeed } from '../Component/EarningFeed/Item.EarningFeed'
import { UserModalContent } from './ModalContent/User.ModalContent'
import { socket } from '../components/Containers/MainContainer'
import { numberWithCommas, getWithdrawFeedImage } from '../components/utils'
import { delegate } from 'tippy.js'

export const EarningFeedContainer = () => {
    // useEffect(() => {
    //     document.addEventListener('handleEarningFeed', function(e){
    //         const EarningFeedDOM = document.querySelector('.earning-feed-container');
    //         if(EarningFeedDOM!=undefined){
    //             if(EarningFeedDOM.classList.contains('earning-feed-container-full')){
    //                 EarningFeedDOM.classList.remove('earning-feed-container-full')
    //             }else{
    //                 EarningFeedDOM.classList.add('earning-feed-container-full')
    //             }
    //         }
    //     })
    // }, [])

    useEffect(() => {
       
            socket.on('activityFeedPacket', (data) => {
                let fp = data.feedPack.splice(0, 10)
                fp.forEach((el, i) => {
                    addEarnFeedItem(el)
                })
    
            })
            socket.on('activityFeed', (data) => {
                addEarnFeedItem(data)
            })  
    }, [])
    const [earningItems, setEarningItems] = useState({})
    const addEarnFeedItem = async (data) => {
        setTimeout(() => {
            let efEl = document.createElement('div');
            efEl.setAttribute('id', `earnFeed-${data['id']}`);
            efEl.setAttribute('class', `earnFeed-item`);
            efEl.setAttribute('href', `/user/${data['userId']}`);

            efEl.setAttribute('data-toggle', `modal`)
            efEl.setAttribute('data-target', `#user-details-modal`)
            efEl.setAttribute('data-user', `${data['userId']}`)
            efEl.classList.add('earnfeed-element')
            efEl.classList.add('animate__animated')
            efEl.classList.add('animate__zoomIn')
            efEl.innerHTML =`
            <div class="earning-feed-item" bis_skin_checked="1"><div class="earning-feed-item-image" bis_skin_checked="1">
                <img src="${data['feedType']=="withdrawal"?`/assets/img/${getWithdrawFeedImage(data['withdrawType'].toLowerCase())}`:`https://api.dicebear.com/9.x/fun-emoji/svg?seed=${data['username']}`}" alt="Earning Feed Item">
            </div>
            <div class="earning-feed-item-content" bis_skin_checked="1">
                <p class="earning-feed-item-content-title">${data['feedType']=="withdrawal"?'Cash Out':data['wall']}</p>
                <p class="earning-feed-item-content-description">${data['username']}</p>
            </div>
            <div class="earning-feed-item-reward" bis_skin_checked="1"
                <div class="earning-feed-item-reward-image" bis_skin_checked="1">
                    <img alt="coin image" class="earning-feed-item-reward-image-coin" loading="lazy" src="/assets/img/faucetify_coin_small.png" style="filter: blur(0px); transition: filter 500ms;">
                    <p class="earning-feed-item-reward-amount">${numberWithCommas(data['coins'])}</p>
                    </div>
                </div>
            </div>`
            try {
                document.querySelector('.earning-feed-container').insertBefore(efEl, document.querySelector('.earning-feed-container').firstChild)
            } catch (e) { }
            if (data['feedType'] == "earn") {
                delegate(`.earning-feed-container`, {
                    target: `#earnFeed-${data['id']}`,
                    content: `
                        <div class='feed-tooltip'>
                            <div>Wall: <p class="primary-feed-tooltip-el">${data['wall']}</p></div>
                            <div>Offer: <p class="primary-feed-tooltip-el">${data['offername']}</p></div>
                            <div>Amount: <p class="primary-feed-tooltip-el">${numberWithCommas(data['coins'])} C</p></div>
                        </div>
                        `,
                    theme: 'personal',
                    allowHTML: true,
                    placement: 'bottom',
                });

            } else if (data['feedType'] == "withdrawal") {
                delegate(`.earning-feed-container`, {
                    target: `#earnFeed-${data['id']}`,
                    content: `
                    <div class='feed-tooltip'>
                    <div>Username: <p class="primary-feed-tooltip-el">${data['username']}</p></div>
                    <div>Withdrawal: <p class="primary-feed-tooltip-el">${data['withdrawType'].toUpperCase()}</p></div>
                    <div>Amount: <p class="primary-feed-tooltip-el">${numberWithCommas(data['coins'])} C</p></div>
                </div>
                        `,
                    theme: 'personal',
                    allowHTML: true,
                    placement: 'bottom',
                });

            }
            if (document.querySelector('.earning-feed-container').children.length > 20) {
                document.querySelector('.earning-feed-container').removeChild(document.querySelector('.earning-feed-container').lastChild)
            }
            document.querySelector(`#earnFeed-${data['id']}`).addEventListener('click', (e) => {
                // document.title = `${data['username']} - PaidCash.co`
                // document.querySelector('.earning-feed').setAttribute('uid-sel', data['userId'])
                // // document.getElementsByTagName('meta')["description"].content = `View ${data['username']}'s profile on PaidCash.co`
                // router.push(`/user/${data['userId']}`, {
                //     params: { uid: data['userId'] }
                // })
                window.dispatchEvent(new CustomEvent('showUserID', { detail: { "userID": data['userId'] } }))
            })
        }, 1);
       
    }
    
    const [showUserDetails, setShowUserDetails] = useState(false)
    const [userdetails, setUserdetails] = useState(0)

    return (
        <div className="earning-feed-container earning-feed-container-full">
            
        </div>
    )
}